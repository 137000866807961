
export const BillingTypeMappingConstant = {
    Table: {
        PRIORITY: "Priority",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        CHARGEON_COMPANY: "Charge-on Company",
        BILLTO_COMPANY: "Bill-to Company",
        OPS_LINE: "Ops Line",
        MARSHALLING_TYPE: "Marshalling Type",
        SIZE: "Size",
        TYPE: "Type",
        STATUS: "Status",
        SHIPMENT_TYPE: "Shipment Type",
        SERVICE_CODE: "Service Code",
        MODALITY_OF_ARRIVAL: "Modality of Arrival",
        MODALITY_OF_DEPARTURE: "Modality of Departure",
        MARSHALLING_FROM: "Marshalling From",
        MARSHALLING_TO: "Marshalling To",
        FROM_TML: "From Tml",
        TO_TML: "To Tml",
        END_VISIT_IND: "End Visit Ind.",
        CHARGE_IND: "Charge Ind.",
        SPECIAL_HANDLING_IND: "Special Handling Ind.",
        SPECIAL_HANDLING_IND_D1: "Special Handling Ind. (D1)",
        BUNDLE_IND: "Bundle Ind.",
        TRANSPORTATION_STATUS: "Transportation Status",
        OPERATION_TYPE: "Operation Type",
        BILLING_TYPE: "Billing Type",
        ACTIVE_IND: "Active Ind.",
    },
    Title: {
        CATEGORY: "Charge",
        TITLE: "Billing Type Setting",
        CHARGE: "Charge",
        VESSEL: "Vessel",
        ADDITIONAL_INFO: "Additional Info",
        INDIRECT_INFO: "Indirect Info",
        BILLING_TYPE: "Billing Type",
        MAPPING_FROM: "From",
        MAPPING_TO: "To",
    },
}
export const billingTypeMappingRequiredFieldList: string[] = [
    'billingType',
    'activeInd',
];

export const billingTypeMappingCSVColumnMapping: string[] = [
    'chargeType',
    'subChargeType',
    'chargeOnCompany',
    'billToCompany',
    'opsLine',
    'marshallingType',
    'cntrSize',
    'cntrType',
    'cntrStatus',
    'shipmentType',
    'serviceCode',
    'modalityOfArrival',
    'modalityOfDeparture',
    'marshallingFrom',
    'marshallingTo',
    'endVisitInd',
    'chargeInd',
    'bundleInd',
    'cntrTspStatus',
    'operationType',
    'fromTml',
    'toTml',
    'billingType',
    'activeInd',
];