import { BillingTypeMappingEntity } from "domain/entity/BillingTypeMapping/BillingTypeMappingEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { BillingTypeMappingRepository } from "./BillingTypeMappingRepo";

export const BillingTypeMappingRepoImpl = (): BillingTypeMappingRepository => {
    
    const searchUrl = "/v1/searchAllBillingTypeMappingList"

    const searchAllBillingTypeMappingList = async() : Promise<BillingTypeMappingEntity[]> => {
        
        return axiosGetData(referenceAxiosInstance,`${searchUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const onApply = async(rows: BillingTypeMappingEntity[]): Promise<ResponseEntity> => {
        const applyUrl = "/v1/billingTypeMappingApply"
        return await axiosPostData(referenceAxiosInstance, `${applyUrl}`, rows);
    }

    const onSave = async(row: BillingTypeMappingEntity): Promise<ResponseEntity> => {
        const saveUrl = "/v1/billingTypeMappingSave"
        return await axiosPostData(referenceAxiosInstance, `${saveUrl}`, row);
    }

    const uploadData =async(csvData: BillingTypeMappingEntity[]): Promise<ResponseEntity> => {
        const uploadUrl = "/v1/billingTypeMappingUpload"
        return await axiosPostData(referenceAxiosInstance, `${uploadUrl}`, csvData);
    }

    return {
        uploadData: uploadData,
        onSave: onSave,
        onApply: onApply,
        searchAllBillingTypeMappingList : searchAllBillingTypeMappingList,
    }
}
