import { BillingTypeMappingConstant, billingTypeMappingRequiredFieldList } from "presentation/constant/BillingTypeMapping/BillingTypeMappingConstant";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { billingTypeDropdownOption, bundleIndDropdownOption, cntrTspStatusDropdownOption, statusDropdownOptions } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useBillingTypeMappingVM } from "presentation/hook/BillingTypeMapping/useBillingTypeMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useBillingTypeMappingTracked } from "presentation/store/BillingTypeMapping/BillingTypeMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, HPHGroupTitle, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const BillingTypeMappingEditPanel = () => {
    const BILLING_TYPE_MAPPING_CONSTANT = BillingTypeMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [billingTypeMappingState] = useBillingTypeMappingTracked();
    const billingTypeMappingVM = useBillingTypeMappingVM();
    const { currentEditRow, isAdd, isEdit } = billingTypeMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (!currentEditRow.billingType) {
            messageBarVM.showError("Billing Type is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await billingTypeMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            billingTypeMappingVM.searchAllBillingTypeMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [billingTypeMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        billingTypeMappingVM.onCloseEidtPanel();
    }, [billingTypeMappingVM]);

    const memoSubChgTypeOptions = useMemo(() => {
        const subChgTypeOptions = currentEditRow.chargeType ? billingTypeMappingState.dynamicOptions.subChargeTypeDropdownOptions[currentEditRow.chargeType] : [];
        return subChgTypeOptions;
    }, [currentEditRow.chargeType, billingTypeMappingState.dynamicOptions.subChargeTypeDropdownOptions])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeType || ''}
                fieldValue={currentEditRow?.chargeType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.CHARGE_TYPE, billingTypeMappingState.dynamicOptions.chargeTypeDropdownOptions, billingTypeMappingVM, currentEditRow?.chargeType, isAdd, isEdit])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.subChargeType || ''}
                fieldValue={currentEditRow?.subChargeType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                maxLength={60}
                options={memoSubChgTypeOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.SUB_CHARGE_TYPE, billingTypeMappingVM, currentEditRow?.subChargeType, isAdd, isEdit, memoSubChgTypeOptions])

    const memoOpsLine = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.opsLine || ''}
                fieldValue={currentEditRow?.opsLine}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.OPS_LINE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'opsLine'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.opsLineDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.OPS_LINE, billingTypeMappingState.dynamicOptions.opsLineDropdownOptions, billingTypeMappingVM, currentEditRow?.opsLine, isAdd, isEdit])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeOnCompany || ''}
                fieldValue={currentEditRow?.chargeOnCompany}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.CHARGEON_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompany'}
                maxLength={60}
                sort={false}
                options={billingTypeMappingState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.CHARGEON_COMPANY, billingTypeMappingState.dynamicOptions.companyCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.chargeOnCompany, isAdd, isEdit])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.billToCompany || ''}
                fieldValue={currentEditRow?.billToCompany}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.BILLTO_COMPANY}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompany'}
                maxLength={60}
                sort={false}
                options={billingTypeMappingState.dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.BILLTO_COMPANY, billingTypeMappingState.dynamicOptions.companyCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.billToCompany, isAdd, isEdit])


    const memoSize = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrSize || ''}
                fieldValue={currentEditRow?.cntrSize}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.SIZE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrSize'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.cntrSizeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.SIZE, billingTypeMappingState.dynamicOptions.cntrSizeDropdownOptions, billingTypeMappingVM, currentEditRow?.cntrSize, isAdd, isEdit])

    const memoCntrType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrType || ''}
                fieldValue={currentEditRow?.cntrType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrType'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.cntrTypeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.TYPE, billingTypeMappingState.dynamicOptions.cntrTypeDropdownOptions, billingTypeMappingVM, currentEditRow?.cntrType, isAdd, isEdit])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrStatus || ''}
                fieldValue={currentEditRow?.cntrStatus}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                maxLength={60}
                options={statusDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.STATUS, billingTypeMappingVM, currentEditRow?.cntrStatus, isAdd, isEdit])

    const memoShipmentType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.shipmentType || ''}
                fieldValue={currentEditRow?.shipmentType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.SHIPMENT_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shipmentType'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.shipmentTypeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.SHIPMENT_TYPE, billingTypeMappingState.dynamicOptions.shipmentTypeDropdownOptions, billingTypeMappingVM, currentEditRow?.shipmentType, isAdd, isEdit])

    const memoServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.serviceCode || ''}
                fieldValue={currentEditRow?.serviceCode}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.SERVICE_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'serviceCode'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.serviceCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.SERVICE_CODE, billingTypeMappingState.dynamicOptions.serviceCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.serviceCode, isAdd, isEdit])

    const memoModalityOfArrival = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfArrival || ''}
                fieldValue={currentEditRow?.modalityOfArrival}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.MODALITY_OF_ARRIVAL, billingTypeMappingState.dynamicOptions.modalityDropdownOptions, billingTypeMappingVM, currentEditRow?.modalityOfArrival, isAdd, isEdit])

    const memoModalityOfDeparture = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.modalityOfDeparture || ''}
                fieldValue={currentEditRow?.modalityOfDeparture}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.modalityDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.MODALITY_OF_DEPARTURE, billingTypeMappingState.dynamicOptions.modalityDropdownOptions, billingTypeMappingVM, currentEditRow?.modalityOfDeparture, isAdd, isEdit])

    const memoMarshalFrom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingFrom || ''}
                fieldValue={currentEditRow?.marshallingFrom}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_FROM}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingFrom'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_FROM, billingTypeMappingState.dynamicOptions.marshalCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.marshallingFrom, isAdd, isEdit])

    const memoMarshalTo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingTo || ''}
                fieldValue={currentEditRow?.marshallingTo}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_TO}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingTo'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.marshalCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_TO, billingTypeMappingState.dynamicOptions.marshalCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.marshallingTo, isAdd, isEdit])

    const memoFromTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.fromTml || ''}
                fieldValue={currentEditRow?.fromTml}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.FROM_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'fromTml'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.terminalCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.FROM_TML, billingTypeMappingState.dynamicOptions.terminalCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.fromTml, isAdd, isEdit])

    const memoToTml = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.toTml || ''}
                fieldValue={currentEditRow?.toTml}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.TO_TML}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'toTml'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.terminalCodeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.TO_TML, billingTypeMappingState.dynamicOptions.terminalCodeDropdownOptions, billingTypeMappingVM, currentEditRow?.toTml, isAdd, isEdit])


    const memoEndVisitInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.endVisitInd || ''}
                fieldValue={currentEditRow?.endVisitInd}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.END_VISIT_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'endVisitInd'}
                maxLength={60}
                options={ActiveIndDroOpts().getDroOptsModel()}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.END_VISIT_IND, billingTypeMappingVM, currentEditRow?.endVisitInd, isAdd, isEdit])



    const memoMarshallingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.marshallingType || ''}
                fieldValue={currentEditRow?.marshallingType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'marshallingType'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.marshallingTypeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.MARSHALLING_TYPE, billingTypeMappingState.dynamicOptions.marshallingTypeDropdownOptions, billingTypeMappingVM, currentEditRow?.marshallingType, isAdd, isEdit])

    const memoChargeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.chargeInd || ''}
                fieldValue={currentEditRow?.chargeInd}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.CHARGE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeInd'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.chargeIndDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.CHARGE_IND, billingTypeMappingState.dynamicOptions.chargeIndDropdownOptions, billingTypeMappingVM, currentEditRow?.chargeInd, isAdd, isEdit])

    const memoSpecialHandlingInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingInd || ''}
                fieldValue={currentEditRow?.specialHandlingInd}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.SPECIAL_HANDLING_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingInd'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.SPECIAL_HANDLING_IND, billingTypeMappingState.dynamicOptions.specHandingIndDropdownOptions, billingTypeMappingVM, currentEditRow?.specialHandlingInd, isAdd, isEdit])

    const memoSpecialHandlingIndD1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.legSpHandlingInd || ''}
                fieldValue={currentEditRow?.legSpHandlingInd}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'legSpHandlingInd'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.specHandingIndDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.SPECIAL_HANDLING_IND_D1, billingTypeMappingState.dynamicOptions.specHandingIndDropdownOptions, billingTypeMappingVM, currentEditRow?.legSpHandlingInd, isAdd, isEdit])

    const memoBoundleInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.bundleInd || ''}
                fieldValue={currentEditRow?.bundleInd}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.BUNDLE_IND}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'bundleInd'}
                maxLength={60}
                options={bundleIndDropdownOption}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.BUNDLE_IND, billingTypeMappingVM, currentEditRow?.bundleInd, isAdd, isEdit])


    const memoTspStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.cntrTspStatus || ''}
                fieldValue={currentEditRow?.cntrTspStatus}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.TRANSPORTATION_STATUS}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrTspStatus'}
                maxLength={60}
                options={cntrTspStatusDropdownOption}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.TRANSPORTATION_STATUS, billingTypeMappingVM, currentEditRow?.cntrTspStatus, isAdd, isEdit])



    const memoOperationType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.operationType || ''}
                fieldValue={currentEditRow?.operationType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.OPERATION_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'operationType'}
                maxLength={60}
                options={billingTypeMappingState.dynamicOptions.operationTypeDropdownOptions}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.OPERATION_TYPE, billingTypeMappingState.dynamicOptions.operationTypeDropdownOptions, billingTypeMappingVM, currentEditRow?.operationType, isAdd, isEdit])


    const memoBillingType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.billingType || ''}
                fieldValue={currentEditRow?.billingType}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.BILLING_TYPE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billingType'}
                maxLength={60}
                options={billingTypeDropdownOption}
                requiredFieldList={billingTypeMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    billingTypeMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.BILLING_TYPE, billingTypeMappingVM, currentEditRow?.billingType, isAdd, isEdit])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "260px", marginBottom: "20px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={BILLING_TYPE_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => billingTypeMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [BILLING_TYPE_MAPPING_CONSTANT.ACTIVE_IND, billingTypeMappingVM, currentEditRow?.activeInd, isAdd, isEdit])

    const handleEdit = useCallback(() => {
        billingTypeMappingVM.onEditClick();
    }, [billingTypeMappingVM]);

    const handleReset = useCallback(() => {
        billingTypeMappingVM.onResetClick(isAdd);
    }, [billingTypeMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: BillingTypeMappingConstant.Title.CATEGORY },
                    { title: BillingTypeMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}
                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New Billing Type Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.billingType)}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>
                        <br />
                        <HPHGroupTitle titleText={BillingTypeMappingConstant.Title.MAPPING_FROM} />
                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoChargeOnCompany}
                            {memoBillToCompany}
                        </CriteriaItemContainer>
                        <label>CONTAINER</label>
                        <CriteriaItemContainer>
                            {memoOpsLine}
                            {memoSize}
                            {memoCntrType}
                            {memoCntrStatus}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoShipmentType}
                            {memoServiceCode}
                            {memoModalityOfArrival}
                            {memoModalityOfDeparture}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoMarshalFrom}
                            {memoMarshalTo}
                            {memoFromTml}
                            {memoToTml}
                        </CriteriaItemContainer>

                        <label>OTHERS</label>
                        <CriteriaItemContainer>
                            {memoMarshallingType}
                            {memoChargeInd}
                            {memoSpecialHandlingInd}
                            {memoSpecialHandlingIndD1}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTspStatus}
                            {memoOperationType}
                            {memoBoundleInd}
                            {memoEndVisitInd}
                        </CriteriaItemContainer>

                        <HPHGroupTitle titleText={BillingTypeMappingConstant.Title.MAPPING_TO} />
                        <CriteriaItemContainer>
                            {memoBillingType}
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default memo(BillingTypeMappingEditPanel);
