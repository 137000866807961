import { BillingTypeMappingEntity, EMPTY_BILLING_TYPE_MAPPING_ENTITY } from "domain/entity/BillingTypeMapping/BillingTypeMappingEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { BillingTypeMappingRepository } from "domain/repository/BillingTypeMapping/BillingTypeMappingRepo";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { SpecialHandlingIndMappingRepository } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepo";
import _ from "lodash";
import { billingTypeMappingCSVColumnMapping } from "presentation/constant/BillingTypeMapping/BillingTypeMappingConstant";
import { BillingTypeMappingModel } from "presentation/model/BillingTypeMapping/BillingTypeMappingModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface BillingTypeMappingVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<BillingTypeMappingModel>> | ((value: SetStateAction<BillingTypeMappingModel>) => void),
    ],
    billingTypeMappingRepo:BillingTypeMappingRepository,
    chargeTypeRepo: ChargeTypeRepository,
    specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepository,
    opsLineRepo: OpsLineRepository,
    masterDataRepo: MasterDataRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
}

export const BillingTypeMappingVM = ({dispatch, billingTypeMappingRepo,chargeTypeRepo,specialhandlingIndMappingRepo,
    opsLineRepo,masterDataRepo,companyRepo,parameterDetailRepo
}:BillingTypeMappingVMProps) => {
    const [billingTypeMappingDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypeList = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let newChargeTypes = newChargeTypeList.filter(entity => entity.chargeCategory === 'GEN' || entity.chargeCategory === 'SYS');

                let chargeTypeDropdownOption:DropdownProps[] = [];
                let subChargeTypeDropdownOption:{[key:string]: DropdownProps[]} = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOption.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOption.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }
                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOption[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOption[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOption[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOption,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOption
                    }
                }))
            }
        )

        await specialhandlingIndMappingRepo.getAllSpecialHandlingIndMappings().then(
            specialHandlingIndList => {
                const specialHandlingindDropdownOption = specialHandlingIndList?.map((specialHandlingInd) => ({
                    dropdownLabel: specialHandlingInd.specialHandlingInd,
                    tagLabel: specialHandlingInd.specialHandlingInd,
                    value: specialHandlingInd.specialHandlingInd,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        specHandingIndDropdownOptions: specialHandlingindDropdownOption,
                    }
                }))
            }
        )
        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel:opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )


        await masterDataRepo.getMasterDataByKey(MasterDataType.MARSHAL).then(
            marshalEntities => {
                const marshalCodeDropdownOptions = marshalEntities?.map((marshal)=>({
                    dropdownLabel: marshal.code,
                    tagLabel: marshal.code,
                    value: marshal.code,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshalCodeDropdownOptions: marshalCodeDropdownOptions,
                    }
                }))
            }           
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalityEntities => {
                const modalityCodeDropdownOptions = modalityEntities?.map((modality)=>({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: modalityCodeDropdownOptions,
                    }
                }))
            }           
        )


        await masterDataRepo.getMasterDataByKey(MasterDataType.SERVICE).then(
            serviceCodes => {
                const serviceCodeDropdownOptions = serviceCodes?.map((serviceCode)=>({
                    dropdownLabel: serviceCode.code,
                    tagLabel: serviceCode.code,
                    value: serviceCode.code,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        serviceCodeDropdownOptions: serviceCodeDropdownOptions
                    }
                }))
            }           
        );
        await masterDataRepo.getMasterDataByKey(MasterDataType.CHARGE_IND).then(
            chargeInds => {
                const chargeIndDropdownOptions = chargeInds?.map((chargeInd)=>({
                    dropdownLabel: chargeInd.code,
                    tagLabel: chargeInd.code,
                    value: chargeInd.code,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeIndDropdownOptions: chargeIndDropdownOptions
                    }
                }))
            }           
        );


        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: companyCodeDropdownOption,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("MARSHALLING_TYPE").then(
            marshallingTypeList => {
                const marshallingTypeDropdownOption = marshallingTypeList?.map((marshallingType) => ({
                    dropdownLabel: marshallingType.parameterDtlCode,
                    tagLabel: marshallingType.parameterDtlCode,
                    value: marshallingType.parameterDtlCode,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        marshallingTypeDropdownOptions: marshallingTypeDropdownOption
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizeList => {
                const sizeDropdownOption = cntrSizeList?.map((cntrSize) => ({
                    dropdownLabel: cntrSize.parameterDtlCode,
                    tagLabel: cntrSize.parameterDtlCode,
                    value: cntrSize.parameterDtlCode,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrSizeDropdownOptions: sizeDropdownOption
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_TYPE").then(
            cntrTypeList => {
                const typeDropdownOption = cntrTypeList?.map((cntrType) => ({
                    dropdownLabel: cntrType.parameterDtlCode,
                    tagLabel: cntrType.parameterDtlCode,
                    value: cntrType.parameterDtlCode,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrTypeDropdownOptions: typeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("SHIPMENT_TYPE").then(
            shipmentTypeList => {
                const shipmentTypeDropdownOption = shipmentTypeList?.map((shipmentType) => ({
                    dropdownLabel: shipmentType.parameterDtlCode,
                    tagLabel: shipmentType.parameterDtlCode,
                    value: shipmentType.parameterDtlCode,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shipmentTypeDropdownOptions: shipmentTypeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("TML_CODE").then(
            tmlCodeList => {
                const tmlCodeDropdownOption = tmlCodeList?.map((tmlCode) => ({
                    dropdownLabel: tmlCode.parameterDtlCode,
                    tagLabel: tmlCode.parameterDtlCode,
                    value: tmlCode.parameterDtlCode,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        terminalCodeDropdownOptions: tmlCodeDropdownOption
                    }
                }))
            }
        )
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("OPERATION_TYPE").then(
            operatingTypeList => {
                const operatingTypeDropdownOption = operatingTypeList?.map((operatingType) => ({
                    dropdownLabel: operatingType.parameterDtlCode,
                    tagLabel: operatingType.parameterDtlCode,
                    value: operatingType.parameterDtlCode,
                })) ?? []

                billingTypeMappingDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operationTypeDropdownOptions: operatingTypeDropdownOption
                    }
                }))
            }
        )

    }

    const searchAllBillingTypeMappingList = async() => {
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentRow: {...EMPTY_BILLING_TYPE_MAPPING_ENTITY},
                currentEditRow: {...EMPTY_BILLING_TYPE_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
                updatedRows:[],
                selectedRows: [],
                billingTypeMappingList: [],
            }
        })
        await billingTypeMappingRepo.searchAllBillingTypeMappingList().then(data =>{
            if(data){
                billingTypeMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        billingTypeMappingList: data,
                        selectedRows: [],
                    }
                })
            }else{
                billingTypeMappingDispatch(prevState => {
                    return {
                        ...prevState,
                        billingTypeMappingList: [],
                    }
                })
            }
        })
    }

    // const onRowDrag = (rows: BillingTypeMappingEntity[]) => {
    //     billingTypeMappingDispatch(prevState =>{ 
    //         const prevRows = prevState.billingTypeMappingList;
    //         const toChangedRows = rows.map((e, index) => ({...e,tempPriority: prevRows[index].priority})).filter(e=>e.tempPriority!==e.priority);      
    //         return ({
    //             ...prevState,
    //             billingTypeMappingList: rows,
    //             updatedRows: toChangedRows,
    //     })})
    // }

    const onApply = async (updatedRows: BillingTypeMappingEntity[]) => {

        return await billingTypeMappingRepo.onApply(updatedRows);
    }

    const updateSelectedRows = async (rows:BillingTypeMappingEntity[]) => {

        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                // billingTypeMappingList: [...allRows],
                selectedRows: rows,
            }
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        if(fieldKey === 'chargeType'){
            billingTypeMappingDispatch(prevState => {
                return {
                    ...prevState,
                    currentEditRow: {
                        ...prevState.currentEditRow,
                        subChargeType: val===prevState.currentEditRow.chargeType?prevState.currentEditRow.subChargeType:''
                    },
                }
            })
        }
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldKey]: val,
                },
            }
        })
    }

    const onRowDoubleClick = async (entity: BillingTypeMappingEntity) => {
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: entity,
                currentRow: entity,
                isShowEditPanel: true,
            }
        })
    }

    const onCloseEidtPanel = async () => {
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_BILLING_TYPE_MAPPING_ENTITY},
                isShowEditPanel: false,
                isAdd: false,
                isEdit: false,
            }
        })
    }

    const onSave = async (currentRow: BillingTypeMappingEntity) => {
        return billingTypeMappingRepo.onSave(currentRow);
    }

    const onAddClick = async () => {
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {...EMPTY_BILLING_TYPE_MAPPING_ENTITY},
                isAdd: true,
                isShowEditPanel: true,
            }
        })
    }

    const onUpload = async (file: File) => {
        
        const arrayBuffer = await readFileAsArrayBuffer(file);
        const billingTypeMappingEntities = await processFileData(arrayBuffer);

        return await billingTypeMappingRepo.uploadData(billingTypeMappingEntities);
    }

    const readFileAsArrayBuffer = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => resolve(e.target?.result);
            reader.onerror = (e) => reject(e.target?.error);

            reader.readAsArrayBuffer(file);
        });
    }


    const processFileData = async (arrayBuffer: any) : Promise<BillingTypeMappingEntity [] > => {
        const byteArray = new Uint8Array(arrayBuffer);        
        const decoder = new TextDecoder('ascii');
        const content = decoder.decode(byteArray);
        const lines = content.split('\n').map(line => line.trim());

        const result: BillingTypeMappingEntity [] = [];
        
        lines.forEach(line => {  
            const obj: BillingTypeMappingEntity  = {...EMPTY_BILLING_TYPE_MAPPING_ENTITY};
            const lineObjArr = line.split(',');
            for (let i = 0; i < billingTypeMappingCSVColumnMapping.length && i < lineObjArr.length; i++) {  
                obj[billingTypeMappingCSVColumnMapping[i]] = _.isEmpty(lineObjArr[i]) ? null : lineObjArr[i];  
            }
            result.push(obj);
        }); 

        return result;
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => { 
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: {
                    ...prevState.currentEditRow,
                    [fieldName]: checked?"Y":"N",
                },
            }
        })
    } 

    const onResetClick = async (isAdd:boolean) => {
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                currentEditRow: isAdd?{...EMPTY_BILLING_TYPE_MAPPING_ENTITY}:prevState.currentRow,
            }
        })
    }
    const onEditClick = async () => {
        billingTypeMappingDispatch(prevState => {
            return {
                ...prevState,
                isEdit: true,
            }
        })
    }

    return {
        onEditClick: onEditClick,
        onResetClick: onResetClick,
        onCheckboxChange: onCheckboxChange,
        onUpload: onUpload,
        onAddClick: onAddClick,
        onSave: onSave,
        onCloseEidtPanel: onCloseEidtPanel,
        onRowDoubleClick: onRowDoubleClick,
        onHeaderFieldChange: onHeaderFieldChange,
        updateSelectedRows: updateSelectedRows,
        onApply: onApply,
        // onRowDrag: onRowDrag,
        loadDropdownOption: loadDropdownOption,
        searchAllBillingTypeMappingList: searchAllBillingTypeMappingList,
    }
}