import { BillingTypeMappingRepoImpl } from "domain/repository/BillingTypeMapping/BillingTypeMappingRepoImpl";
import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { OpsLineRepoImpl } from "domain/repository/OpsLine/OpsLineRepoImpl";
import { ParameterDetailRepoImpl } from "domain/repository/parameter/ParameterDetailRepoImpl";
import { SpecialHandlingIndMappingRepoImpl } from "domain/repository/SpecialHanldingInd/SpecialHandlingIndMappingRepoImpl";
import { useBillingTypeMappingTracked } from "presentation/store/BillingTypeMapping/BillingTypeMappingProvider";
import { BillingTypeMappingVM } from "presentation/viewModel/BillingTypeMapping/BillingTypeMappingVM";
import { useMemo } from "react";

export const useBillingTypeMappingVM = () => {
    const [, setBillingTypeMappingState] = useBillingTypeMappingTracked();
    const billingTypeMappingVM = useMemo(() =>
        BillingTypeMappingVM({
            dispatch: [setBillingTypeMappingState],
            billingTypeMappingRepo: BillingTypeMappingRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            specialhandlingIndMappingRepo: SpecialHandlingIndMappingRepoImpl(),
            opsLineRepo: OpsLineRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            parameterDetailRepo: ParameterDetailRepoImpl(),
        }), [setBillingTypeMappingState])

    return billingTypeMappingVM
}