export interface BillingTypeMappingEntity{

    id?: number | null,
    priority?: number | null,
    tempPriority?: number | null,
    chargeType?: string | null,
    subChargeType?: string | null,
    chargeOnCompany?: string | null,
    billToCompany?: string | null,
    opsLine?: string | null,
    marshallingType?: string | null,
    cntrSize?: string | null,
    cntrType?: string | null,
    cntrStatus?: string | null,
    shipmentType?: string | null,
    serviceCode?: string | null,
    modalityOfArrival?: string | null,
    modalityOfDeparture?: string | null,
    marshallingFrom?: string | null,
    marshallingTo?: string | null,
    fromTml?: string | null,
    toTml?: string | null,
    endVisitInd?: string | null,
    chargeInd?: string | null,
    specialHandlingInd?: string | null,
    legSpHandlingInd?: string | null,
    bundleInd?: string | null,
    cntrTspStatus?: string | null,
    operationType?: string | null,
    billingType?: string | null,
    activeInd: string | null,
    mappingType: string,

    [key: string]: string | boolean | number | null | Object | undefined
}

export const EMPTY_BILLING_TYPE_MAPPING_ENTITY : BillingTypeMappingEntity = {

    id: 0,
    priority: null,
    tempPriority: null,
    chargeType: '',
    subChargeType: '',
    chargeOnCompany: '',
    billToCompany: '',
    opsLine: '',
    marshallingType: '',
    cntrSize: '',
    cntrType: '',
    cntrStatus: '',
    shipmentType: '',
    serviceCode: '',
    modalityOfArrival: '',
    modalityOfDeparture: '',
    marshallingFrom: '',
    marshallingTo: '',
    fromTml: '',
    toTml: '',
    endVisitInd: '',
    chargeInd: '',
    specialHandlingInd: '',
    legSpHandlingInd: '',
    bundleInd: '',
    cntrTspStatus: '',
    operationType: '',
    billingType: '',
    activeInd: 'Y',
    mappingType: "BillingTypeMappingEntity",

}
