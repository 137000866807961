import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { BillingTypeMappingProvider } from "presentation/store/BillingTypeMapping/BillingTypeMappingProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import BillingTypeMappingMaintenance from "presentation/view/section/BillingTypeMapping/BillingTypeMappingMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const BillingTypeMappingContMain = () => {
    return <ANAInfoWrapper permission={Permission.BILLING_TYPE_MAPPING_MAINTENANCE}>
        <MessageBarWrapper>
            <BillingTypeMappingProvider>
                <GridStyles/>
                <BillingTypeMappingMaintenance/>
            </BillingTypeMappingProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default BillingTypeMappingContMain;